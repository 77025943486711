import { numCells } from './config';

const charToCellState: { [key: string]: number } = {
  '-': 0,
  '#': 1,
};

/**
 * Converts an easy-to-author text format that depicts frames of an animation
 * into an array of grid configurations --- one configuration per frame.
 */
function parse(textFrames: string): Array<Array<number>> {
  const frames = textFrames.split('\n\n').map(frame =>
    frame
      .split('')
      .map(char => charToCellState[char])
      .filter(val => val !== undefined)
  );
  frames.forEach(frame => {
    if (frame.length !== numCells) {
      throw new Error(
        `Expected ${numCells} pixels in frame, but frame was: ${frame}`
      );
    }
  });
  return frames;
}

export const diamondExplode = parse(`
  -----
  -----
  -----
  -----
  -----

  -----
  -----
  --#--
  -----
  -----

  -----
  --#--
  -###-
  --#--
  -----

  --#--
  -###-
  #####
  -###-
  --#--

  -###-
  #####
  #####
  #####
  -###-

  #####
  #####
  #####
  #####
  #####

  #####
  #####
  ##-##
  #####
  #####

  #####
  ##-##
  #---#
  ##-##
  #####

  ##-##
  #---#
  -----
  #---#
  ##-##

  #---#
  -----
  -----
  -----
  #---#

  -----
  -----
  -----
  -----
  -----
`);

export const diamondIris = parse(`
  -----
  -----
  -----
  -----
  -----

  #---#
  -----
  -----
  -----
  #---#

  ##-##
  #---#
  -----
  #---#
  ##-##

  #####
  ##-##
  #---#
  ##-##
  #####

  #####
  #####
  ##-##
  #####
  #####

  #####
  #####
  #####
  #####
  #####

  #####
  #####
  #####
  #####
  #####

  #####
  #####
  ##-##
  #####
  #####

  #####
  ##-##
  #---#
  ##-##
  #####

  ##-##
  #---#
  -----
  #---#
  ##-##

  #---#
  -----
  -----
  -----
  #---#

  -----
  -----
  -----
  -----
  -----
`);

export const verticalDoors = parse(`
  -----
  -----
  -----
  -----
  -----

  #---#
  #---#
  #---#
  #---#
  #---#

  ##-##
  ##-##
  ##-##
  ##-##
  ##-##

  #####
  #####
  #####
  #####
  #####

  #####
  #####
  #####
  #####
  #####

  #####
  #####
  #####
  #####
  #####

  ##-##
  ##-##
  ##-##
  ##-##
  ##-##

  #---#
  #---#
  #---#
  #---#
  #---#

  -----
  -----
  -----
  -----
  -----
`);

export const wipeRight = parse(`
  -----
  -----
  -----
  -----
  -----

  #----
  #----
  #----
  #----
  #----

  ##---
  ##---
  ##---
  ##---
  ##---

  ###--
  ###--
  ###--
  ###--
  ###--

  ####-
  ####-
  ####-
  ####-
  ####-

  #####
  #####
  #####
  #####
  #####

  -####
  -####
  -####
  -####
  -####

  --###
  --###
  --###
  --###
  --###

  ---##
  ---##
  ---##
  ---##
  ---##

  ----#
  ----#
  ----#
  ----#
  ----#

  -----
  -----
  -----
  -----
  -----
`);

export const window = parse(`
  -----
  -----
  -----
  -----
  -----

  #####
  -----
  -----
  -----
  -----

  #####
  #####
  -----
  -----
  -----

  #####
  #####
  #####
  -----
  -----

  #####
  #####
  #####
  #####
  -----

  #####
  #####
  #####
  #####
  #####

  #####
  #####
  #####
  #####
  #####

  #####
  #####
  #####
  #####
  #####

  #####
  #####
  #####
  #####
  -----

  #####
  #####
  #####
  -----
  -----

  #####
  #####
  -----
  -----
  -----

  #####
  -----
  -----
  -----
  -----

  -----
  -----
  -----
  -----
  -----
`);
