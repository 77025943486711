import { width, height, numCells } from './config';

function coordinateToIndex(x: number, y: number) {
  return y * width + x;
}

function indexToCoordinate(i: number) {
  return [i % width, Math.floor(i / width)];
}

export class Model {
  cells: number[] = new Array(numCells);
  par = 0;
  movesUsed = 0;

  constructor() {
    this.cells.fill(0);
  }

  /**
   * Generates a random level where each cell has a `chance` probability of
   * being toggled.
   */
  randomLevel(chance: number = 0.5) {
    this.cells.fill(0);
    for (let i = 0; i < this.cells.length; i++) {
      if (Math.random() < chance) {
        this.toggleCell(i);
        this.par++;
      }
    }
    this.par = 0;
    this.movesUsed = 0;
  }

  /**
   * Generates a random level by randomly selecting `moves` many cells and
   * toggling them.
   */
  randomLevelNMoves(moves: number) {
    this.cells.fill(0);
    const indices = [];
    for (let i = 0; i < numCells; i++) indices.push(i);
    // a Fisher-Yates approach to select items from `indices`...
    for (let m = 0; m < moves; m++) {
      const numChoices = numCells - m;
      const choice = Math.floor(Math.random() * numChoices) + m;
      [indices[m], indices[choice]] = [indices[choice], indices[m]];
      this.toggleCell(indices[m]);
    }
    this.par = moves;
    this.movesUsed = 0;
  }

  toggleCell(i: number) {
    this.movesUsed++;
    const [x, y] = indexToCoordinate(i);
    const candidates = [
      [x, y], // center
      [x, y + 1], // top
      [x, y - 1], // bottom
      [x - 1, y], // left
      [x + 1, y], // right
    ];
    candidates
      .filter(([x, y]) => x >= 0 && x < width && y >= 0 && y < height)
      .forEach(([x, y]) => {
        this.cells[coordinateToIndex(x, y)] ^= 1;
      });
  }

  isSolved(): boolean {
    return this.cells.every(cell => cell === 0);
  }
}
