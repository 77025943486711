import { numCells } from './config';
import { Model } from './model';

import * as animations from './animations';

export class View {
  cellEls: Element[] = [];

  constructor(private model: Model, hostEl: Element) {
    const gridEl = document.createElement('div');
    gridEl.classList.add('grid');

    for (let cellIdx = 0; cellIdx < numCells; cellIdx++) {
      const cellEl = document.createElement('div');
      cellEl.addEventListener('click', () => {
        this.toggleCell(cellIdx);
      });
      cellEl.classList.add('cell');
      this.cellEls.push(cellEl);
      gridEl.appendChild(cellEl);
    }

    hostEl.appendChild(gridEl);
    this.newGame();
  }

  newGame() {
    this.model.randomLevelNMoves(4);
    this.render();
  }

  render() {
    this.drawFrame(this.model.cells);
  }

  drawFrame(frame: number[]) {
    for (let cellIdx = 0; cellIdx < numCells; cellIdx++) {
      const cellEl = this.cellEls[cellIdx];
      const cellState = frame[cellIdx];
      if (cellState === 0) {
        cellEl.classList.remove('cell--on');
      } else {
        cellEl.classList.add('cell--on');
      }
    }
  }

  playAnimation(frames: Array<Array<number>>) {
    return new Promise(resolve => {
      let delay = 0;
      for (let frame of frames) {
        setTimeout(() => {
          this.drawFrame(frame);
        }, delay);
        delay += 100;
      }
      setTimeout(resolve, delay);
    });
  }

  toggleCell(i: number) {
    this.model.toggleCell(i);
    this.render();
    if (this.model.isSolved()) {
      const animation =
        this.model.movesUsed > this.model.par
          ? animations.window
          : animations.diamondExplode;
      this.playAnimation(animation).then(() => {
        this.newGame();
      });
    }
  }
}
